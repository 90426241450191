<template>

            <form @submit.prevent="save">

                <div class="row">

                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label for="name">Nombre</label>
                            <input type="text" v-model="model.name" required id="name" class="form-control form-control-lg" placeholder="Nombre del proveedor">
                        </div>                        
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label for="name">Móvil</label>
                            <input type="text" v-model="model.mobile" required id="name" class="form-control form-control-lg" placeholder="Número de teléfono móvil">
                        </div>                        
                        
                    </div>
                </div>


                <div class="row">

                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label for="name">Teléfono fijo</label>
                            <input type="text" v-model="model.phone" required id="name" class="form-control form-control-lg" placeholder="Número de teléfono fijo">
                        </div>                        
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label for="name">RFC</label>
                            <input type="text" v-model="model.rfc" required id="name" class="form-control form-control-lg" placeholder="RFC">
                        </div>                        
                        
                    </div>

                </div>


                <div class="form-group">
                            <label for="name">Correo electrónico</label>
                            <input type="text" v-model="model.email" required id="name" class="form-control form-control-lg" placeholder="Correo electrónico">
                        </div>                        

                <div class="form-group">
                    <label for="name">Dirección</label>
                    <textarea v-model="model.address" required id="name" rows="5" class="form-control form-control-lg" placeholder="Dirección"></textarea>
                </div>                        




                

                <div class="form-group">
                    <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>
                </div>
            </form>

</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import api from '@/util/api.js';




export default {
    routeName:'vendors',
    name: 'formVendor',
    components:{
        submitbutton,
    },

    props:{

        id:{
            type:Number,
            required:false,
        }

    },  
    data(){

        return {
            
            model:{
                id:'',
                name:'',
                phone:'',
                email:'',
                mobile:'',
                address:'',
                rfc:''


            },
            
            pic:'',
            loading:false,


        }

    },

    methods:{

        selectFile(event) {
         this.model.icon = event.target.files[0];
        },

        save(){

            
            const data = this.createFormData(this.model);
            this.loading=true;

            api.request({
                url:this.me(this.isNewRecord?'/':this.model.id),
                data,
                headers:{'Content-type':'form/data'},
                method:this.isNewRecord?'POST':'PATCH',
                }).then(response=>{

                    this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});
                    this.$emit('afterSave',response);
                    
                    
                }).catch(error=>{
                    
                    this.$notify({message:"Ocurrió un error",type:"error"});
                    console.log(error);

                }).finally(()=>{

                        this.loading=false;
                });
            
        }




    },


    
    
    mounted(){



        




        

        if(!this.isNewRecord){
                api.get(this.me(this.$props.id))
                .then(response=>{
                    
                        const data=response.data;

                        for (const key in data) {
                            if (this.model.hasOwnProperty(key)) {
                                this.model[key] = data[key];
                            }
                        }


                        
                        this.pic=this.$apiUrl+'/../..'+data.icon.replace('.','-sm.');


                }).catch(error=>{
                    console.log(error);
                });

        }



    }


}

</script>